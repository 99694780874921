.google-auth-button {
    background-color: #4285f4;
    color: rgb(46, 242, 46);
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  .google-auth-button:hover {
    background-color: #357ae8;
  }