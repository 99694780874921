.small-fail {
    border-radius: 25px;
    margin:auto;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #e04f71;
    padding: 20px;
    width: 500px;
    height: 25px;
    text-align:center;
    font-size: large;
    color: rgb(255, 255, 255);
}

.add-tournament-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tournament-form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
  }
  
  input[type="date"],
  select {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  select[multiple] {
    height: 100px; /* Adjust height as needed */
  }

  .school-checkboxes div {
  display: block;
  margin-bottom: 5px; /* Adjust spacing between each checkbox and label */
}
  .school-checkboxes div {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
  }
  
  label {
    font-weight: normal;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  /* Separate input fields onto different lines */
  .modal-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Position buttons */
  .modal-content button {
    margin-top: 10px;
  }
  
  /* Style the close button */
  .close-modal {
    background-color: #ff0000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-modal:hover {
    background-color: #cc0000;
  }