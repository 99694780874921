body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }



  .main {
    padding: 20px;
  }
  .tournament-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    background: #f2f2f2;
    padding: 15px;
    margin: 10px;
    display: inline-block;
    width: 30%;
    vertical-align: top;
  }
  .tournament-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .add-tournament-card {
    display: inline-block;
    width: 30%;
    height: 200px;
    margin: 10px;
    text-align: center;
    line-height: 200px;
    font-size: 50px;
    color: #757575;
    background: #e7e7e7;
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
  }
  .add-tournament-card:hover {
    background-color: #ddd;
  }
  .tab {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    padding-top: 250px;
  }
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  .tab button:hover {
    color: rgb(191, 185, 185)
  }
  .tab button.active {
    background-color: #6a0dad;
    color: white;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
  }
  
  .close-button {
    float: right;
    font-size: 1.5em;
    cursor: pointer;
  }