body, html {
    margin: 0;
    height: 100%;
    font-family: Arial, sans-serif;
  }

  .main {
    padding: 20px;
  }

  .rank-section {
    background-color: #f2f2f2;
    padding: 10px;
    margin-top: 20px;
  }
  .rank-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  .rank-table th, .rank-table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  .rank-table th {
    background-color: #eee;
  }
  .update-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }