body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    /* padding: 20px; */
  }


  .edittournamentheader{
    background-image: linear-gradient(#58046b, #22058a);
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2px + 2vmin);
    color: white;
  }


  .schools, .schedule, footer {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .school, .round {
    background-color: #eee;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  button {
    background-color: #8a2be2;
    color: #fff;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, th, td {
    border: 1px solid #ddd;
  }

  th, td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #8a2be2;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }